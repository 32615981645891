import React, {useState, useEffect, useRef} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import axios from 'axios';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useReactToPrint, ReactToPrint} from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';
import Box from '@mui/material/Box';

// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Oracoes.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function Oracao(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  	const [oracao, setOracao] = useState({id: -1, id_tipo_oracao: -1, texto_oracao_pt: "", texto_oracao_lt: "", nome_oracao: "", url: ""});

    function getPrintCarteira(text) {
        return (
            <div style={{width: "50%", padding: "10px"}}>
                <p style={{whiteSpace: "pre-line"}}>{text}</p>
            </div>
        )
    }

    function getPrintCarteiraLatim(text) {
        return (
            <div style={{width: "50%", padding: "10px"}}>
                <p style={{whiteSpace: "pre-line"}}>{text}</p>
            </div>
        )
    }

    function getPrintFolhaInteira(text) {
        return (
            <div style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "0px"}}>
                <div style={{width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "0px"}}>
                    <div class="musica-home-header" style={{marginTop: "30px", marginBottom: "20px", width: "100%"}}>
                        <Divider sx={{
                            "&::before, &::after": {
                            borderColor: "#bc1200",
                            },
                        }}>
                            {oracao.nome_oracao}
                        </Divider>
                    </div>
                    <p style={{whiteSpace: "pre-line", fontSize: "20px"}}>{text}
                    </p>
                </div>
            </div>
        )
    }

    function getPrintFolhaInteiraLatim(text) {
        return (
            <div style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "0px"}}>
                <div style={{width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "0px"}}>
                    <div class="musica-home-header" style={{marginTop: "30px", marginBottom: "20px", width: "100%"}}>
                        <Divider sx={{
                            "&::before, &::after": {
                            borderColor: "#bc1200",
                            },
                        }}>
                            {oracao.nome_oracao}
                        </Divider>
                    </div>
                    <p style={{whiteSpace: "pre-line", fontSize: "20px"}}>{text}
                    </p>
                </div>
            </div>
        )
    }

    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        var url = window.location.href;

        var l = url.split("/");
        var tipoOracao = l[l.length - 1];

        var getUrl = baseUrl + "/informacao-oracao?url="+tipoOracao;

        axios.get(getUrl)
            .then(resp => {
                console.log("resp is");
                console.log(resp.data);
                setOracao(resp.data);
            });
    }, []);

    var textPt = oracao.texto_oracao_pt.split(/(<strong>.*?<\/strong>)/).map(segment => {
                    if (segment.includes('strong')) {
                    const text = segment.replace('<strong>','').replace('</strong>', '');
                    return <strong>{text}</strong>;
                    } else {
                    return segment;
                    }
                    })


    var textLt = oracao.texto_oracao_lt.split(/(<strong>.*?<\/strong>)/).map(segment => {
                    if (segment.includes('strong')) {
                    const text = segment.replace('<strong>','').replace('</strong>', '');
                    return <strong>{text}</strong>;
                    } else {
                    return segment;
                    }
                    })

    var oracoesPesquisa = [];

    if (window.innerWidth <= 768) {
       return (
        <OracaoMobile 
            oracao={oracao}
            getPrintCarteira={getPrintCarteira}
            getPrintCarteiraLatim={getPrintCarteiraLatim}
            getPrintFolhaInteira={getPrintFolhaInteira}
            getPrintFolhaInteiraLatim={getPrintFolhaInteiraLatim}
            textPt={textPt}
            textLt={textLt}
        />);
    } else {
       return (
        <OracaoDesktop
            oracao={oracao}
            getPrintCarteira={getPrintCarteira}
            getPrintCarteiraLatim={getPrintCarteiraLatim}
            getPrintFolhaInteira={getPrintFolhaInteira}
            getPrintFolhaInteiraLatim={getPrintFolhaInteiraLatim}
            textPt={textPt}
            textLt={textLt}
         />);
    }
}

function OracaoDesktop(props) {

    const refCarteira = useRef();
    const refCarteiraLatim = useRef();
    const refFolhaInteira = useRef();
    const refFolhaInteiraLatim = useRef();

    var headerImprimir;

    if (props.textLt == null || props.textLt == "") {
        headerImprimir = (<Box style={{ width: "60%", minWidth: "430px", display: "flex", justifyContent: "center", flexDirection: "row", justifyContent: "space-evenly"}} 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 2,
                            bgcolor: 'background.paper',
                            color: 'text.secondary',
                            '& svg': {
                                m: 1,
                            },
                        }}>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refCarteira.current}
                                trigger={() => (
                                     <div style={{display: "flex", alignItems: "center"}}>
                                         <PrintIcon style={{marginLeft: "0px"}}/>
                                         <p>Tamanho carteira</p>
                                     </div>
                                 )}
                            /> 
                        </div>
                        <Divider style={{height: "100%", rotate: "90deg", width: "20px"}}/>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refFolhaInteira.current}
                                trigger={() => (
                                    <div style={{display: "flex", alignItems: "center"}}>
                                     <PrintIcon style={{marginLeft: "0px"}}/>
                                     <p>Folha inteira</p>
                                    </div>
                                 )}
                            />
                            
                        </div>
                    </Box>);
    } else {
        headerImprimir = (<Box style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "row", justifyContent: "space-evenly"}} 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 2,
                            bgcolor: 'background.paper',
                            color: 'text.secondary',
                            '& svg': {
                                m: 1,
                            },
                        }}>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refCarteira.current}
                                trigger={() => (
                                     <div style={{display: "flex", alignItems: "center"}}>
                                         <PrintIcon style={{marginLeft: "0px"}}/>
                                         <p>Tamanho carteira</p>
                                     </div>
                                 )}
                            /> 
                        </div>
                        <Divider style={{height: "100%", rotate: "90deg", width: "20px"}}/>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refCarteiraLatim.current}
                                trigger={() => (
                                     <div style={{display: "flex", alignItems: "center"}}>
                                        <PrintIcon style={{marginLeft: "0px"}}/>
                                        <p>Tamanho carteira Latim</p>
                                     </div>
                                 )}
                            />
                        </div>
                        <Divider style={{height: "100%", rotate: "90deg", width: "20px"}}/>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refFolhaInteira.current}
                                trigger={() => (
                                    <div style={{display: "flex", alignItems: "center"}}>
                                     <PrintIcon style={{marginLeft: "0px"}}/>
                                     <p>Folha inteira</p>
                                    </div>
                                 )}
                            />
                            
                        </div>
                        <Divider style={{height: "100%", rotate: "90deg", width: "20px"}}/>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refFolhaInteiraLatim.current}
                                trigger={() => (
                                     <div style={{display: "flex", alignItems: "center"}}>
                                        <PrintIcon style={{marginLeft: "0px"}}/>
                                        <p>Folha inteira Latim</p>
                                     </div>
                                 )}
                            />
                        </div>
                    </Box>);
    }

    var textoOracoes;

    if (props.textLt == null || props.textLt == "") {
        textoOracoes = (<><div style={{width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", marginTop: "0px"}}>
                    <p style={{whiteSpace: "pre-line", fontSize: "1rem"}}>{props.textPt}
                    </p>
                </div></>);
    } else {
        textoOracoes = (<><div style={{width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", marginTop: "0px"}}>
                    <p style={{whiteSpace: "pre-line", fontSize: "1rem"}}>{props.textPt}
                    </p>
                </div>
                <div style={{ width: "100%", height: "50px", display: "flex", alignItems: "center"}}>
                    <Divider style={{width: "100%"}} sx={{
                    borderColor: "#bc1200"
                    }} />
                </div>
                <div style={{width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start"}}>
                    <p style={{whiteSpace: "pre-line", fontSize: "1rem"}}>{props.textLt}</p>
                </div></>);
    }


    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div style={{display: "none"}}>
                <div ref={refCarteira}>
                    {props.getPrintCarteira(props.textPt)}
                </div>
            </div>
            <div style={{display: "none"}}>
                <div ref={refCarteiraLatim}>
                    {props.getPrintCarteiraLatim(props.textLt)}
                </div>
            </div>
            <div style={{display: "none"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} ref={refFolhaInteira}>
                    {props.getPrintFolhaInteira(props.textPt)}
                </div>
            </div>
            <div style={{display: "none"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} ref={refFolhaInteiraLatim}>
                    {props.getPrintFolhaInteiraLatim(props.textLt)}
                </div>
            </div>
            <div style={{marginTop: "20px", width: "90%"}}>
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    <h1 class="musica-home-header" style={{margin: 0}}>
                        {props.oracao.nome_oracao}
                    </h1>
                </Divider>
            </div>
            <div style={{width: "80%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div style={{width: "100%", height: "90px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    {headerImprimir}
                </div>
                {textoOracoes}
            </div>
        </div>);
}

function OracaoMobile(props) {
    
    const refCarteira = useRef();
    const refCarteiraLatim = useRef();
    const refFolhaInteira = useRef();
    const refFolhaInteiraLatim = useRef();

    var headerImprimir;

    if (props.textLt == null || props.textLt == "") {
        headerImprimir = (<Box style={{ width: "100%", display: "flex", justifyContent: "space-evenly", flexDirection: "column"}} 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 2,
                            bgcolor: 'background.paper',
                            color: 'text.secondary',
                            '& svg': {
                                m: 1,
                            },
                        }}>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refCarteira.current}
                                trigger={() => (
                                     <div style={{display: "flex", alignItems: "center"}}>
                                         <PrintIcon style={{marginLeft: "0px"}}/>
                                         <p>Tamanho carteira</p>
                                     </div>
                                 )}
                            /> 
                        </div>
                        <Divider style={{width: "100%"}}/>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refFolhaInteira.current}
                                trigger={() => (
                                    <div style={{display: "flex", alignItems: "center"}}>
                                     <PrintIcon style={{marginLeft: "0px"}}/>
                                     <p>Folha inteira</p>
                                    </div>
                                 )}
                            />
                            
                        </div>
                    </Box>);
    } else {
        headerImprimir = (<Box style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", justifyContent: "space-evenly"}} 
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 2,
                            bgcolor: 'background.paper',
                            color: 'text.secondary',
                            '& svg': {
                                m: 1,
                            },
                        }}>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer", margin: "3px", width: "80%"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refCarteira.current}
                                trigger={() => (
                                     <div style={{display: "flex", alignItems: "center"}}>
                                         <PrintIcon style={{marginLeft: "0px"}}/>
                                         <p>Tamanho carteira</p>
                                     </div>
                                 )}
                            /> 
                        </div>
                        <Divider style={{width: "100%"}}/>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer", margin: "3px", width: "80%"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refCarteiraLatim.current}
                                trigger={() => (
                                     <div style={{display: "flex", alignItems: "center"}}>
                                        <PrintIcon style={{marginLeft: "0px"}}/>
                                        <p>Tamanho carteira Latim</p>
                                     </div>
                                 )}
                            />
                        </div>
                        <Divider style={{width: "100%"}}/>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer", margin: "3px", width: "80%"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refFolhaInteira.current}
                                trigger={() => (
                                    <div style={{display: "flex", alignItems: "center"}}>
                                     <PrintIcon style={{marginLeft: "0px"}}/>
                                     <p>Folha inteira</p>
                                    </div>
                                 )}
                            />
                            
                        </div>
                        <Divider style={{width: "100%"}}/>
                        <div style={{display: "flex", alignItems: "center", cursor: "pointer", margin: "3px", width: "80%"}}>
                            <ReactToPrint
                                bodyClass="print-agreement"
                                content={() => refFolhaInteiraLatim.current}
                                trigger={() => (
                                     <div style={{display: "flex", alignItems: "center"}}>
                                        <PrintIcon style={{marginLeft: "0px"}}/>
                                        <p>Folha inteira Latim</p>
                                     </div>
                                 )}
                            />
                        </div>
                    </Box>);
    }

    var textoOracoes;

    if (props.textLt == null || props.textLt == "") {
        textoOracoes = (<><div style={{width: "95%%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px"}}>
                    <p style={{whiteSpace: "pre-line"}}>{props.textPt}
                    </p>
                </div></>);
    } else {
        textoOracoes = (<><div style={{width: "95%%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10px"}}>
                    <p style={{whiteSpace: "pre-line"}}>{props.textPt}
                    </p>
                </div>
                <div style={{ width: "100%", height: "50px", display: "flex", alignItems: "center"}}>
                    <Divider style={{width: "100%"}} sx={{
                    borderColor: "#bc1200"
                    }} />
                </div>
                <div style={{width: "95%%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <p style={{whiteSpace: "pre-line"}}>{props.textLt}</p>
                </div></>);
    }


    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div style={{display: "none"}}>
                <div ref={refCarteira}>
                    {props.getPrintCarteira(props.textPt)}
                </div>
            </div>
            <div style={{display: "none"}}>
                <div ref={refCarteiraLatim}>
                    {props.getPrintCarteiraLatim(props.textLt)}
                </div>
            </div>
            <div style={{display: "none"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} ref={refFolhaInteira}>
                    {props.getPrintFolhaInteira(props.textPt)}
                </div>
            </div>
            <div style={{display: "none"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}} ref={refFolhaInteiraLatim}>
                    {props.getPrintFolhaInteiraLatim(props.textLt)}
                </div>
            </div>
            <div class="musica-home-header" style={{marginTop: "0px", marginBottom: "8px"}}>
                <Divider 
                    sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}
                    
                >
                    <h1
                        style={{
                            whiteSpace: "break-spaces",
                            fontSize: "6.5vw",
                            maxWidth: "80vw",
                            width: "max-content",
                            fontWeight: "lighter"
                        }}
                    >
                        {props.oracao.nome_oracao}
                    </h1>
                </Divider>
            </div>
            <div style={{width: "90%", display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                {textoOracoes}
                <div style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "30px"}}>
                    {headerImprimir}
                </div>
            </div>
        </div>);
}