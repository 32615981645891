import React, {useState, useEffect} from 'react';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Carousel from "react-multi-carousel";
import YouTube from 'react-youtube';
import YouTubeIcon from '@mui/icons-material/YouTube';
import "react-multi-carousel/lib/styles.css";
import { NavLink } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import "./Home.css";
import line from "../images/home-page/Line 1.png";
import consagracao from "../images/home-page/consagracao3.jpg";
import consagracaoMobile from "../images/home-page/consagracao-expandido.png";

// Imagens musicas liturgicas
import Entrada from "../images/home-page/home-musicas/Entrada.png";
import Amem from "../images/home-page/home-musicas/Amem.png";
import Aclamacao from "../images/home-page/home-musicas/aclamacao.png";
import CantoFinal from "../images/home-page/home-musicas/CantoFinal.png";
import AtoPenitencial from "../images/home-page/home-musicas/AtoPenitencial.png";
import Comunhao from "../images/home-page/home-musicas/Comunhao.png";
import Cordeiro from "../images/home-page/home-musicas/Cordeiro.png";
import Gloria from "../images/home-page/home-musicas/Gloria.png";
import Marianas from "../images/home-page/home-musicas/Marianas.png";
import Ofertorio from "../images/home-page/home-musicas/Ofertorio.png";
import Salmo from "../images/home-page/home-musicas/Salmo.png";
import Santo from "../images/home-page/home-musicas/Santo.png";
import Natal from "../images/home-page/home-musicas/Natal.png";
import Quaresma from "../images/home-page/home-musicas/Quaresma.png";
import Pascoa from "../images/home-page/home-musicas/Pascoa.png";
import Ladainhas from "../images/home-page/home-musicas/Ladainhas.png";
import Advento from "../images/home-page/home-musicas/Advento.png";
import Pentecostes from "../images/home-page/home-musicas/Pentecostes.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Imagens oracoes
import Oracoes from "../images/home-page/Oracoes.png";

// Imagens liturgia
import LivroAberto from "../images/home-page/home-liturgia/livro-aberto.png";
import PadreHomilia from "../images/home-page/home-liturgia/padre-homilia.png";
import SantoAntonio from "../images/home-page/home-liturgia/santo-antonio.png";
import Calendario from "../images/home-page/home-liturgia/calendario.jpg";

// Vídeos
import Exercicios from "../images/home-page/exercicios.jpg";
import Filme from "../images/home-page/filme.jpg";
import MariaLujan from "../images/home-page/maria-lujan.jpg";

// Imagens subsidios para missa
import Documentos from "../images/home-page/home-subsidios/Documentos.png";
import RoteiroMissa from "../images/home-page/home-subsidios/Roteiros.png";
import Rituais from "../images/home-page/home-subsidios/Rituais.png";

// Imagens artigos formativos
import RetanguloBranco from "../images/home-page/retangulo-branco.png";
import PadrePio from "../images/home-page/Padre-pio.png";

// baseUrl
import {baseUrl} from "../services/adalConfig.js";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function Home(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [proximaMissa, setProximaMissa] = useState({"id": -1, "url": "", "nome": "", data: ""});
    const [artigosMaisRecentes, setArtigosMaisRecentes] = useState([]);
    const navigate = useNavigate();

     useEffect(() => {
        var date = new Date();
        date = date.toISOString().substring(0,10);

        var getUrl = baseUrl + "/datas-liturgicas?date=" + date;

        axios.get(getUrl)
            .then(resp => {
                if (resp.data != null && resp.data != "") {
                    setProximaMissa(resp.data);
                }
            });
    }, []);
 
    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        var getUrl = baseUrl + "/get-artigos-recentes";

        axios.get(getUrl)
            .then(resp => {
                setArtigosMaisRecentes(resp.data); 
            });
    }, []);
 
    if (window.innerWidth <= 768) {
       return (<HomeMobile proximaMissa={proximaMissa} artigosMaisRecentes={artigosMaisRecentes}/>);
    } else {
       return (<HomeDesktop proximaMissa={proximaMissa} artigosMaisRecentes={artigosMaisRecentes}/>);
    }
};

function ArtigoMedio(props) {
    if (props.artigo) {
        return (
        <a href={`/artigos/${props.artigo.url}`} class="home-artigos-carousel-format" style={{color: "black", textDecoration: "none", height: "350px"}}>
            <div class="home-artigos-card" style={{height: "100%", justifyContent: "flex-start"}}>
                <img draggable="false" src={`data:image/png;base64,${props.artigo.imagem}`} style={{width: "90%", height: "160px", objectFit: "cover", objectPosition: "top", marginTop: "15px", cursor: "pointer"}}/>
                <h3 style={{width: "90%", fontSize: "18px", marginTop: "14px", cursor: "pointer"}}>{props.artigo.nome}</h3>
                <p style={{width: "90%", "-webkit-line-clamp": "5", "-webkit-box-orient": "vertical", lineHeight: "21px", overflow: "hidden", display: "-webkit-box", marginTop: "6px", "font-size": "calc(1.0rem + 0vw)"}}>{props.artigo.resumo}</p>
            </div>
        </a>
        )
    } else {
        return <div></div>;
    }
}

function HomeDesktop(props) {

    const navigate = useNavigate();

    const opts = {
        height: '300px',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
    };

    function pegarDataAtual() {
        const diasDaSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

        const dataAtual = new Date();

        const diaSemana = diasDaSemana[dataAtual.getDay()];
        const diaDoMes = dataAtual.getDate();
        const mes = meses[dataAtual.getMonth()];
        const ano = dataAtual.getFullYear();

        const dataFormatada = `${diaSemana}, ${diaDoMes} de ${mes} de ${ano}`;

        return dataFormatada;
    }

    const responsive = {
        desktop: {
          breakpoint: { max: 300000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
    };

   function _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }

    return <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "80px", overflow: "hidden"}}>
        <div class="welcome-message">
            <h1 class="nome-pagina" >Portal da Liturgia</h1>
            <img draggable="false" style={{width: "350px", heigth: "50px"}} src={line}/>
            <div></div>
            <p style={{fontSize: "18px"}}><i>LAUDATE DOMINUM OMNES GENTES</i></p>
            <p class="bordao"><b>Nós amamos a Liturgia</b>.</p>
            <p class="bordao" style={{margin: 0}}>Vamos juntos dar à Santa Missa o lugar de dignidade que ela merece ter na igreja e na nossa vida.</p>
            <DoubleArrowIcon className="home-arrow-animation" style={{rotate: "90deg", marginTop: "10px", width: "40px", height: "40px", marginBottom: "18px"}}/>
        </div>
        <img draggable="false" style={{width: "100%", heigth: "40px"}} src={consagracao}/>
        <div class="musica-liturgica welcome-message" style={{width: "90%"}}>
            <h2 class="home-sessao"><NavLink to="/musica-liturgica" style={{marginTop: "40px", color: "black", textDecoration: "none"}}>Música Litúrgica</NavLink></h2>
            <p class="home-subtitulo" style={{width: "77.777777%"}}>Para nós, o canto e a música sacra tem uma importância fundamental por sua grande incidência cultual, espiritual, cultural, formativa, estética, comunitária... É uma forma eminente de tributar digno culto a Deus que não contradiga a grandeza de sua infinita majestade.
            <p class="home-subtitulo"><b>Pe. Carlos Miguel Buela</b></p></p>
            <div style={{width: "100%"}}>
                {
                    ((props.proximaMissa.id != -1)
                    &&
                    <div style={{height: "70px", display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "15px"}}>
                        <div class="home-call-to-action" style={{margin: "0px"}}>
                            <NavLink to={"/musica-liturgica/missa/"+props.proximaMissa.url} style={{ color: "black"}}>
                                <p style={{fontSize: "calc(1.0rem + 0.5vw)"}}><b><u>Próxima solenidade: {props.proximaMissa.nome}</u></b></p><p>{props.proximaMissa.data.substring(8,10) + "/" + props.proximaMissa.data.substring(5,7) + "/" + props.proximaMissa.data.substring(0,4)}</p>
                            </NavLink>
                        </div>
                    </div>)
                    ||
                    <div style={{height: "20px"}}></div>
                }
                <Carousel 
                    class="home-musicas"
                    // additionalTransfrom={0}
                    arrows
                    // renderButtonGroupOutside={true}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    // centerMode={false}
                    // dotListClass=""
                    // draggable
                    // focusOnSelect={false}
                    infinite
                    pauseOnHover
                    showDots
                    // renderDotsOutside
                    // itemClass=""
                    // keyBoardControl
                    // minimumTouchDrag={80}
                    // pauseOnHover
                    // renderArrowsWhenDisabled={true}
                    // renderButtonGroupOutside={false}
                    // renderDotsOutside={false}
                    // centerMode={true}
                    // partialVisible
                    responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 5,
                          partialVisibilityGutter: 0
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 5,
                          partialVisibilityGutter: 0
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 5,
                          partialVisibilityGutter: 0  
                        }
                      }}
                    // rewind={false}
                    // rewindWithAnimation={false}
                    // rtl={false}
                    // shouldResetAutoplay
                    // showDots={false}
                    // sliderClass=""
                    // slidesToSlide={1}
                    // swipeable
                >
                    
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/entrada")}>
                            <img draggable="false" src={Entrada} class="image"/>
                            <div class="overlay">
                                <p class="text">Entrada</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/santo")}>
                            <img draggable="false" src={Santo}class="image"/>
                            <div class="overlay">
                                <p class="text">Santo</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/ato-penitencial")}>
                            <img draggable="false" src={AtoPenitencial} class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "1rem"}}>Ato Penitencial</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/amem")}>
                            <img draggable="false" src={Amem}class="image"/>
                            <div class="overlay">
                                <p class="text">Doxologia</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/gloria")}>
                            <img draggable="false" src={Gloria}class="image"/>
                            <div class="overlay">
                                <p class="text">Glória</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/cordeiro-de-deus")}>
                            <img draggable="false" src={Cordeiro}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "1rem"}}>Cordeiro de Deus</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/salmo")}>
                            <img draggable="false" src={Salmo}class="image"/>
                            <div class="overlay">
                                <p class="text">Salmo</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/comunhao")}>
                            <img draggable="false" src={Comunhao}class="image"/>
                            <div class="overlay">
                                <p class="text">Comunhão</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/ofertorio")}>
                            <img draggable="false" src={Ofertorio}class="image"/>
                            <div class="overlay">
                                <p class="text">Ofertório</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica#Ladainha")}>
                            <img draggable="false" src={Marianas}class="image"/>
                            <div class="overlay">
                                <p class="text">Ladainhas</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/aclamacao")}>
                            <img draggable="false" src={Aclamacao}class="image"/>
                            <div class="overlay">
                                <p class="text">Aclamação</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/canto-final")}>
                            <img draggable="false" src={CantoFinal}class="image"/>
                            <div class="overlay">
                                <p class="text">Canto Final</p>
                            </div>
                        </div>
                    </div>                    
                    <div class="home-coluna-musicas" >
                        <div class="container" onClick={() => navigate("/musica-liturgica/advento")}>
                            <img draggable="false" src={Advento}class="image"/>
                            <div class="overlay">
                                <p class="text">Advento</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/natal")}>
                            <img draggable="false" src={Natal}class="image"/>
                            <div class="overlay">
                                <p class="text">Natal</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/marianas")}>
                            <img draggable="false" src={Ladainhas}class="image"/>
                            <div class="overlay">
                                <p class="text">Marianas</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/quaresma")}>
                            <img draggable="false" src={Quaresma} class="image"/>
                            <div class="overlay">
                                <p class="text">Quaresma</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/pascoa")}>
                            <img draggable="false" src={Pascoa}class="image"/>
                            <div class="overlay">
                                <p class="text">Páscoa</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/missa/pentecostes")}>
                            <img draggable="false" src={Pentecostes}class="image"/>
                            <div class="overlay">
                                <p class="text">Pentecostes</p>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
        <div class="musica-liturgica welcome-message" style={{width: "100%"}}>
            <h2 class="home-sessao"><NavLink to="/oracoes" style={{marginTop: "40px", color: "black", textDecoration: "none"}}>Orações</NavLink></h2>
            <p class="home-subtitulo" style={{width: "70%"}}><b>“Quem reza se salva, quem não reza é condenado.</b></p>
            <p class="home-subtitulo" style={{width: "70%"}}> Salvar-se sem rezar é dificilíssimo, até mesmo impossível... mas rezando, a salvação é certa e facilíssima. Se não orarmos, não temos desculpas, porque a graça de rezar é dada a todos... se não nos salvarmos, a culpa será toda nossa, porque não teremos rezado.”</p>
            <p class="home-subtitulo" style={{width: "70%"}}><b>Santo Afonso Maria de Ligório</b></p>
            <div style={{width: "100%", marginTop: "40px", display: "flex", justifyContent: "center"}}>
                <div class="home-teste-oracoes">
                    <div class="home-oracoes-teste-linha">
                        <a class="home-oracoes-topico" style={{"text-decoration": "none"}} href="/oracoes#Novenas">Novenas</a>
                        <a class="home-oracoes-topico" style={{"text-decoration": "none"}} href="/oracoes/tema/comuns">Diárias</a>
                        <a class="home-oracoes-topico" style={{"text-decoration": "none"}} href="/oracoes/tema/acao-de-gracas">Ação de Graças</a>
                    </div>
                    <div style={{width: "80%", height: "15px"}}></div>
                    <div class="home-oracoes-teste-linha">
                        <a class="home-oracoes-topico" style={{"text-decoration": "none"}} href="/oracoes/tema/santos">Santos</a>
                        <a class="home-oracoes-topico" style={{"text-decoration": "none"}} href="/oracoes#Ladainha">Ladainhas</a>
                        <a class="home-oracoes-topico" style={{"text-decoration": "none"}} href="/oracoes/tema/preparacao-para-missa">Preparação para missa</a>
                    </div>
                    <div style={{width: "80%", height: "15px"}}></div>
                    <div class="home-oracoes-teste-linha">
                        <a class="home-oracoes-topico" style={{"text-decoration": "none"}} href="/oracoes#Terco">Terços</a>
                        <a class="home-oracoes-topico" style={{"text-decoration": "none"}} href="/oracoes/tema/via-sacra">Via Sacra</a>
                        <a class="home-oracoes-topico" style={{backgroundColor: "#ff1800", textDecoration: "none"}} href="/oracoes">TODAS</a>
                    </div>
                </div>
                {/* <div style={{width: "15%", height: "100%"}}></div> */}
            </div>
        </div>
        <div class="musica-liturgica welcome-message" style={{width: "100%", marginTop: "45px"}}>
            <h2 class="home-sessao" style={{marginBottom: "2px"}}>Liturgia</h2>
            <p style={{textAlign: "center", fontSize: "20px"}}>{pegarDataAtual()}</p>
            <div style={{width: "90%", marginTop: "30px", display: "flex", justifyContent: "space-between"}}>
                <div class="container">
                    <a target="_blank" href="https://www.cnbb.org.br/liturgia-diaria/">
                    <img style={{maxWidth: "400px"}} draggable="false" src={LivroAberto} class="image liturgia-image"/>
                    <div class="overlay" style={{width: "100%", maxWidth: "400px",height: "55px", top: "72%"}}>
                        <p class="text liturgia-image">Liturgia diária</p>
                    </div>
                    </a>
                </div>
                <div class="container">
                    <a target="_blank" href="https://open.spotify.com/show/3QEuFdv0jhZzYwVmwNsSp8">
                    <img style={{maxWidth: "400px"}} draggable="false" src={PadreHomilia} class="image liturgia-image"/>
                    <div class="overlay" style={{width: "100%", maxWidth: "400px",height: "55px", top: "72%"}}>
                        <p class="text liturgia-image">Homilia diária</p>
                    </div>
                    </a>
                </div>
                <div class="container">
                    <a target="_blank" href="https://www.vaticannews.va/pt/santo-do-dia.html">
                    <img style={{maxWidth: "400px"}} draggable="false" src={SantoAntonio} class="image liturgia-image"/>
                    <div class="overlay" style={{width: "100%", maxWidth: "400px",height: "55px", top: "72%"}}>
                        <p class="text liturgia-image">Santo do dia</p>
                    </div>
                    </a>
                </div>
                <div class="container">
                    <a target="_blank" href="https://www.sagradaliturgia.com.br/index2.php">
                        <img style={{maxWidth: "400px"}} draggable="false" src={Calendario} class="image liturgia-image"/>
                        <div class="overlay" style={{width: "100%", maxWidth: "400px",height: "55px", top: "72%"}}>
                            <p class="text liturgia-image" style={{fontSize: "calc(0.8rem + 1vw)"}}>Calendário Litúrgico</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="home-videos-sessao musica-liturgica welcome-message">
            <a href="https://www.youtube.com/@portaldaliturgia7822" target="_blank" style={{display: "flex", alignItems: "center", color: "white", textDecoration: "none"}}>
                <YouTubeIcon style={{color: "white", marginRight: "8px"}}/>
                <h2 class="home-sessao" style={{marginBottom: "2px", color: "white"}}>Youtube - Portal da Liturgia</h2>
            </a>
            <div style={{height: "300px", width: "100%", marginTop: "15px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Carousel 
                    // additionalTransfrom={0}
                    arrows
                    // renderButtonGroupOutside={true}
                    autoPlay
                    autoPlaySpeed={3000}
                    // centerMode={false}
                    // dotListClass=""
                    // draggable
                    // focusOnSelect={false}
                    infinite
                    pauseOnHover
                    showDots
                    // renderDotsOutside
                    // itemClass=""
                    // keyBoardControl
                    // minimumTouchDrag={80}
                    // pauseOnHover
                    // renderArrowsWhenDisabled={true}
                    // renderButtonGroupOutside={false}
                    // renderDotsOutside={false}
                    // centerMode={true}
                    // partialVisible
                    responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 2,
                          partialVisibilityGutter: 0
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 2,
                          partialVisibilityGutter: 0
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 2,
                          partialVisibilityGutter: 0  
                        }
                      }}
                    // rewind={false}
                    // rewindWithAnimation={false}
                    // rtl={false}
                    // shouldResetAutoplay
                    // showDots={false}
                    // sliderClass=""
                    // slidesToSlide={1}
                    // swipeable
                    className="home-videos-carousel"
                >
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "95%", height: "100%"}}>
                            <YouTube videoId="MnbknikM6Mg" opts={opts}/>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "95%", height: "100%"}}>
                            <YouTube videoId="880jlTncRiU" opts={opts}/>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "95%", height: "100%"}}>
                            <YouTube videoId="IA3mlIJvrkE" opts={opts}/>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "95%", height: "100%"}}>
                            <YouTube videoId="tmx6jmzD1-M" opts={opts}/>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
        <div class="musica-liturgica welcome-message" style={{width: "100%", marginBottom: "30px"}}>
            <h2 class="home-sessao" style={{marginBottom: "2px"}}>Subsídios para missa</h2>
            <p class="home-subtitulo" style={{width: "70%", marginTop: "20px"}}>“A Santa Missa é a grande escola do amor cristão. Ela abre as portas todos os dias, e irá abri-las até o fim do mundo, até que Ele venha (1 Cor 11:26). Para todos os que queiram aprender a amar como Cristo, ela é uma mestra solícita, que não só ensina com as palavras, mas, muito mais que isso, com o mesmo ato.”</p>
            <p style={{marginBottom: "30px"}} class="home-subtitulo"><b>Livro Nuestra Missa, Pe. Carlos M. Buela</b></p>
            <div style={{width: "100%", display: "flex", gap: "30px 40px", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                <a class="container" href="/documentos" style={{color: "white", textDecoration: "none"}}>
                    <img draggable="false" src={Documentos} class="image liturgia-image"/>
                    <div class="overlay" style={{width: "100%", maxWidth: "100%", height: "55px", top: "72%"}}>
                        <p class="text liturgia-image" style={{fontSize: "calc(0.5rem + 1vw)"}}>Documentos da igreja</p>
                    </div>
                </a>
                <a class="container" href="/roteiros" style={{color: "white", textDecoration: "none"}}>
                    <img draggable="false" src={RoteiroMissa} class="image liturgia-image"/>
                    <div class="overlay" style={{width: "100%", maxWidth: "100%", height: "55px", top: "72%"}}>
                        <p class="text liturgia-image" style={{fontSize: "calc(0.5rem + 0.8vw)"}}>Roteiros/Comentários para missa</p>
                    </div>
                </a>
                <a class="container" href="/rituais" style={{color: "white", textDecoration: "none"}}>
                    <img draggable="false" src={Rituais} class="image liturgia-image"/>
                    <div class="overlay" style={{width: "100%", maxWidth: "100%", height: "55px", top: "72%"}}>
                        <p class="text liturgia-image" style={{fontSize: "calc(1rem + 1vw)"}}>Rituais</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="musica-liturgica welcome-message" style={{width: "100%", marginBottom: "50px",     marginTop: "0"}}>
            <h2 class="home-sessao" style={{marginBottom: "20px"}}><NavLink to="/artigos" style={{color: "black", textDecoration: "none"}}>Artigos formativos</NavLink></h2>
            <p class="home-call-to-action" style={{ marginBottom: "25px", marginTop: "0", cursor: "pointer", width: "auto"}}><b><u><NavLink to="/artigos" style={{color: "black", textDecoration: "none"}}>ACESSE TODOS OS ARTIGOS >></NavLink></u></b></p>
            <div style={{width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "30px", justifyContent: "center"}}>
                { props.artigosMaisRecentes.slice(0,10).map((item) => (<ArtigoMedio artigo={item}/>))}
            </div>
            <p class="home-call-to-action" style={{marginTop: "25px", cursor: "pointer", width: "auto"}}><b><u><NavLink to="/artigos" style={{color: "black", textDecoration: "none"}}>ACESSE TODOS OS ARTIGOS >></NavLink></u></b></p>
        </div>
    </div>
}

function HomeMobile(props) {

    const navigate = useNavigate();

    const opts = {
        height: '200px',
        width: '100%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 0,
        },
    };

    function pegarDataAtual() {
        const diasDaSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const meses = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];

        const dataAtual = new Date();

        const diaSemana = diasDaSemana[dataAtual.getDay()];
        const diaDoMes = dataAtual.getDate();
        const mes = meses[dataAtual.getMonth()];
        const ano = dataAtual.getFullYear();

        const dataFormatada = `${diaSemana}, ${diaDoMes} de ${mes} de ${ano}`;

        return dataFormatada;
    }

    const responsive = {
        desktop: {
          breakpoint: { max: 1000000, min: 768 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 768, min: 0 },
          items: 1
        }
    };

   function _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }

    return <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "80px", overflow: "hidden"}}>
        <div class="welcome-message">
            <h1 class="nome-pagina" >Portal da Liturgia</h1>
            <img draggable="false" style={{width: "350px", heigth: "50px"}} src={line}/>
            <div></div>
            <p style={{fontSize: "20px", textAlign: "center"}}><i>LAUDATE DOMINUM OMNES GENTES</i></p>
            <p class="bordao"><b>Nós amamos a Liturgia</b></p>
            <p class="bordao" style={{margin: 0}}>Vamos juntos dar à Santa Missa o lugar de dignidade que ela merece ter na igreja e na nossa vida.</p>
            <DoubleArrowIcon className="home-arrow-animation" style={{width: "40px", height: "40px", rotate: "90deg", marginTop: "10px", marginBottom: "18px"}}/>
        </div>
        <img draggable="false" style={{width: "100%", heigth: "40px"}} src={consagracaoMobile}/>
        <div class="musica-liturgica welcome-message">
            <h2 class="home-sessao"><NavLink to="/musica-liturgica" style={{color: "black", textDecoration: "none"}}>Música Litúrgica</NavLink></h2>
            <p class="home-subtitulo" style={{marginBottom: "10px"}}>Para nós, o canto e a música sacra são uma forma eminente de tributar digno culto a Deus que não contradiga a grandeza de sua infinita majestade.</p>
            {
                    (props.proximaMissa.id != -1)
                    &&
                    <div style={{display: "flex", alignItems: "center", marginTop: "15px", marginBottom: "25px"}}>
                        <div class="home-call-to-action" style={{margin: "0px"}}>
                            <NavLink to={"/musica-liturgica/missa/"+props.proximaMissa.url} style={{ color: "black"}}>
                                <p><b><u>Próxima solenidade: {props.proximaMissa.nome}</u></b></p><p>{props.proximaMissa.data.substring(8,10) + "/" + props.proximaMissa.data.substring(5,7) + "/" + props.proximaMissa.data.substring(0,4)}</p>
                            </NavLink>
                        </div>
                    </div>
                }
            <div style={{width: "100%"}}>
                <Carousel 
                    class="home-musicas"
                    // additionalTransfrom={0}
                    arrows
                    //autoPlay
                    autoPlaySpeed={3000}
                    // centerMode={false}
                    // dotListClass=""
                    // draggable
                    // focusOnSelect={false}
                    infinite
                    pauseOnHover
                    showDots
                    // itemClass=""
                    // keyBoardControl
                    // minimumTouchDrag={80}
                    // pauseOnHover
                    // renderArrowsWhenDisabled={false}
                    // renderButtonGroupOutside={false}
                    // renderDotsOutside={false}
                    responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 5,
                          partialVisibilityGutter: 40
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 3,
                          partialVisibilityGutter: 30
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 3,
                          partialVisibilityGutter: 30
                        }
                      }}
                    // rewind={false}
                    // rewindWithAnimation={false}
                    // rtl={false}
                    // shouldResetAutoplay
                    // showDots={false}
                    // sliderClass=""
                    // slidesToSlide={1}
                    // swipeable={false}
                >
                    
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/entrada")}>
                            <img draggable="false" src={Entrada} class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Entrada</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/santo")}>
                            <img draggable="false" src={Santo}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Santo</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/ato-penitencial")}>
                            <img draggable="false" src={AtoPenitencial} class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)", lineHeight: "17px"}}>Ato Penitencial</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/amem")}>
                            <img draggable="false" src={Amem}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Doxologia</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/gloria")}>
                            <img draggable="false" src={Gloria}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Glória</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/cordeiro-de-deus")}>
                            <img draggable="false" src={Cordeiro}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)", lineHeight: "17px"}}>Cordeiro de Deus</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/salmo")}>
                            <img draggable="false" src={Salmo}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Salmo</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/comunhao")}>
                            <img draggable="false" src={Comunhao}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Comunhão</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/ofertorio")}>
                            <img draggable="false" src={Ofertorio}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Ofertório</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica#Ladainha")}>
                            <img draggable="false" src={Marianas}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Ladainhas</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/aclamacao")}>
                            <img draggable="false" src={Aclamacao}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Aclamação</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/canto-final")}>
                            <img draggable="false" src={CantoFinal}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)", lineHeight: "17px"}}>Canto Final</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/advento")}>
                            <img draggable="false" src={Advento}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Advento</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/natal")}>
                            <img draggable="false" src={Natal}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Natal</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/tema/marianas")}>
                            <img draggable="false" src={Ladainhas}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Marianas</p>
                            </div>
                        </div>
                        <div class="container"  onClick={() => navigate("/musica-liturgica/quaresma")}>
                            <img draggable="false" src={Quaresma}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Quaresma</p>
                            </div>
                        </div>
                    </div>
                    <div class="home-coluna-musicas">
                        <div class="container" onClick={() => navigate("/musica-liturgica/pascoa")}>
                            <img draggable="false" src={Pascoa}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Páscoa</p>
                            </div>
                        </div>
                        <div class="container" onClick={() => navigate("/musica-liturgica/missa/pentecostes")}>
                            <img draggable="false" src={Pentecostes}class="image"/>
                            <div class="overlay">
                                <p class="text" style={{fontSize: "calc(0.6rem + 1.5vw)"}}>Pentecostes</p>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
        <div class="musica-liturgica welcome-message" style={{width: "100%"}}>
            <h2 class="home-sessao"><NavLink to="/oracoes" style={{color: "black", textDecoration: "none"}}>Orações</NavLink></h2>
            <p class="home-subtitulo" style={{width: "90%"}}><b>“Quem reza se salva, quem não reza é condenado.</b> Salvar-se sem rezar é dificilíssimo, até mesmo impossível... se não nos salvarmos, a culpa será toda nossa, porque não teremos rezado.”</p>
            <p class="home-subtitulo" style={{width: "90%"}}><b>Santo Afonso Maria de Ligório</b></p>
            <div style={{width: "100%", marginTop: "10px", display: "flex", justifyContent: "center"}}>
                <div class="home-teste-oracoes">    
                    <a href="/oracoes#Novenas" style={{"text-decoration": "none"}} class="home-teste-oracoes-topico">Novenas</a>
                    <a style={{"text-decoration": "none"}} href="/oracoes/tema/comuns" class="home-teste-oracoes-topico">Diárias</a>
                    <a style={{"text-decoration": "none"}} href="/oracoes/tema/acao-de-gracas" class="home-teste-oracoes-topico">Ação de graças</a>
                    <a style={{"text-decoration": "none"}} href="/oracoes#Ladainha" class="home-teste-oracoes-topico">Ladainhas</a>
                    <a style={{"text-decoration": "none"}} href="/oracoes#Terco" class="home-teste-oracoes-topico">Terços</a>
                    <a style={{"text-decoration": "none"}} href="/oracoes/tema/via-sacra" class="home-teste-oracoes-topico">Via Sacra</a>
                    <a style={{"text-decoration": "none", fontSize: "calc(0.5rem + 2.5vw)"}} href="/oracoes/tema/preparacao-para-missa" class="home-teste-oracoes-topico">Preparação missa</a>
                    <a style={{"text-decoration": "none"}} href="/oracoes/tema/santos" class="home-teste-oracoes-topico">Santos</a>
                    <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <a class="home-teste-oracoes-topico" style={{backgroundColor: "#ff1800", marginBottom: "0px", textDecoration: "none"}} href="/oracoes"><p>TODAS</p></a>
                    </div>
                </div>
                {/* <div style={{width: "35%", height: "100%"}}>
                    <img draggable="false" style={{width: "95%", cursor: "default"}} src={Oracoes} class="image"/>
                </div>
                <div class="home-oracoes">
                    <div class="home-oracoes-linha">
                        <a href="/oracoes#Novenas"><p class="home-oracoes-topico">Novenas</p></a>
                        <p class="home-oracoes-topico">Liturgia das horas</p>
                    </div>
                    <div class="home-oracoes-linha">
                        <p class="home-oracoes-topico">Oração dos santos</p>
                        <p class="home-oracoes-topico">Ladainhas</p>
                    </div>
                    <div class="home-oracoes-linha">
                        <p class="home-oracoes-topico">Terços</p>
                        <p class="home-oracoes-topico">Via Sacra</p>
                    </div>
                    <div class="home-oracoes-linha">
                        <p class="home-oracoes-topico">Ação de graças</p>
                        <p class="home-oracoes-topico">Preparação para missa</p>
                    </div>
                    <p class="home-oracoes-linha home-call-to-action-oracoes" style={{cursor: "pointer"}}><b><u>ACESSE TODAS AS ORAÇÕES >></u></b></p>
                </div> */}
                {/* <div style={{width: "15%", height: "100%"}}></div> */}
            </div>
        </div>
        <div class="musica-liturgica welcome-message" style={{width: "100%", marginTop: "25px"}}>
            <h2 class="home-sessao" style={{marginBottom: "2px"}}>Liturgia</h2>
            <p style={{textAlign: "center"}}>{pegarDataAtual()}</p>
            <div style={{width: "90%", marginTop: "30px", display: "flex", flexDirection: "row",    justifyContent: "space-around", flexWrap: "wrap", gap: "40px 0"}}>
                
                <div class="container" style={{width: "fit-content"}}>
                    <a target="_blank" href="https://www.cnbb.org.br/liturgia-diaria/">
                        <img draggable="false" src={LivroAberto} class="image" style={{width: "100%"}}/>
                        <div class="overlay" style={{width: "100%", height: "55px", top: "72%"}}>
                            <p class="text">Liturgia diária</p>
                        </div>
                    </a>
                </div>
                <div class="container" style={{width: "fit-content"}}>
                    <a target="_blank" href="https://open.spotify.com/show/3QEuFdv0jhZzYwVmwNsSp8">
                    <img draggable="false" src={PadreHomilia} class="image" style={{width: "100%"}}/>
                    <div class="overlay" style={{width: "100%", height: "55px", top: "72%"}}>
                        <p class="text">Homilia diária</p>
                    </div>
                    </a>
                </div>

            
                <div class="container" style={{width: "fit-content"}}>
                    <a target="_blank" href="https://www.vaticannews.va/pt/santo-do-dia.html">
                    <img draggable="false" src={SantoAntonio} class="image" style={{width: "100%"}}/>
                    <div class="overlay" style={{width: "100%", height: "55px", top: "72%"}}>
                        <p class="text">Santo do dia</p>
                    </div>
                    </a>
                </div>
                <div class="container" style={{width: "fit-content"}}>
                    <a target="_blank" href="https://www.sagradaliturgia.com.br/index2.php">
                        <img draggable="false" src={Calendario} class="image" style={{width: "100%"}}/>
                        <div class="overlay" style={{width: "100%", height: "55px", top: "72%"}}>
                            <p class="text">Calendário Litúrgico</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="home-videos-sessao musica-liturgica welcome-message" style={{width: "100%", height: "370px"}}>
            <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
                <a href="https://www.youtube.com/@portaldaliturgia7822" target="_blank" style={{display: "flex", alignItems: "center", color: "white", textDecoration: "none"}}>
                    <YouTubeIcon style={{color: "white", marginRight: "8px"}}/>
                    <h2 style={{fontSize: "32px", textAlign: "center", marginBottom: "20px", cursor: "pointer", marginBottom: "2px", color: "white"}}>Youtube</h2>
                </a>
                <h2 style={{fontSize: "32px", textAlign: "center", marginBottom: "20px", cursor: "pointer", marginBottom: "2px", color: "white"}}>Portal da Liturgia</h2>
            </div>
            <div style={{height: "230px", width: "100%", marginTop: "15px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Carousel 
                    // additionalTransfrom={0}
                    arrows
                    // renderButtonGroupOutside={true}
                    autoPlay
                    autoPlaySpeed={4000}
                    // centerMode={false}
                    // dotListClass=""
                    // draggable
                    // focusOnSelect={false}
                    infinite
                    pauseOnHover
                    showDots
                    // renderDotsOutside
                    // itemClass=""
                    // keyBoardControl
                    // minimumTouchDrag={80}
                    // pauseOnHover
                    // renderArrowsWhenDisabled={true}
                    // renderButtonGroupOutside={false}
                    // renderDotsOutside={false}
                    // centerMode={true}
                    // partialVisible
                    responsive={{
                        desktop: {
                          breakpoint: {
                            max: 3000,
                            min: 1024
                          },
                          items: 1,
                          partialVisibilityGutter: 0
                        },
                        mobile: {
                          breakpoint: {
                            max: 464,
                            min: 0
                          },
                          items: 1,
                          partialVisibilityGutter: 0
                        },
                        tablet: {
                          breakpoint: {
                            max: 1024,
                            min: 464
                          },
                          items: 1,
                          partialVisibilityGutter: 0  
                        }
                      }}
                    // rewind={false}
                    // rewindWithAnimation={false}
                    // rtl={false}
                    // shouldResetAutoplay
                    // showDots={false}
                    // sliderClass=""
                    // slidesToSlide={1}
                    // swipeable
                    className="home-videos-carousel"
                >
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "95%", height: "100%"}}>
                            <YouTube videoId="MnbknikM6Mg" opts={opts}/>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "95%", height: "100%"}}>
                            <YouTube videoId="880jlTncRiU" opts={opts}/>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "95%", height: "100%"}}>
                            <YouTube videoId="IA3mlIJvrkE" opts={opts}/>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{width: "95%", height: "100%"}}>
                            <YouTube videoId="tmx6jmzD1-M" opts={opts}/>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
        {/* <div class="container" style={{width: "48%"}}>
            <img draggable="false" src={SantoAntonio} class="image" style={{width: "100%"}}/>
            <div class="overlay" style={{width: "100%", height: "55px", top: "72%"}}>
                <p class="text" style={{fontSize: "24px"}}>Santo do dia</p>
            </div>
        </div> */}
        <div class="musica-liturgica welcome-message" style={{width: "100%", marginBottom: "30px"}}>
            <h2 class="home-sessao" style={{marginBottom: "2px"}}>Subsídios para missa</h2>
            <p class="home-subtitulo" style={{width: "90%", marginTop: "20px"}}>“A Santa Missa é a grande escola do amor cristão. Ela abre as portas todos os dias, e irá abri-las até o fim do mundo, até que Ele venha (1 Cor 11:26)”</p>
            <p class="home-subtitulo" style={{width: "90%"}}><b>Pe. Carlos M. Buela</b></p>
            <div style={{width: "90%", marginTop: "30px", display: "flex", flexFlow: "row wrap", justifyContent: "space-around", gap: "40px 0px"}}>
                <a href="/documentos" class="container" style={{width: "fit-content", color: "white", textDecoration: "none"}}>
                    <img draggable="false" src={Documentos} class="image" style={{width: "100%"}}/>
                    <div class="overlay" style={{width: "100%", height: "55px", top: "72%"}}>
                        <p class="text">Documentos da igreja</p>
                    </div>
                </a>
                <a href="/roteiros" class="container" style={{width: "fit-content", color: "white", textDecoration: "none"}}>
                    <img draggable="false" src={RoteiroMissa} class="image" style={{width: "100%"}}/>
                    <div class="overlay" style={{width: "100%", height: "55px", top: "72%"}}>
                        <p class="text">Roteiros para missa</p>
                    </div>
                </a>
                <a href="/rituais" class="container" style={{width: "fit-content", color: "white", textDecoration: "none"}}>
                    <img draggable="false" src={Rituais} class="image" style={{width: "100%"}}/>
                    <div class="overlay" style={{width: "100%", height: "55px", top: "72%"}}>
                        <p class="text">Rituais</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="musica-liturgica welcome-message" style={{width: "100%", marginBottom: "50px"}}>
            <h2 class="home-sessao" style={{marginBottom: "25px"}}><NavLink to="/artigos" style={{color: "black", textDecoration: "none"}}>Artigos formativos</NavLink></h2>
            <div style={{width: "90%", display: "flex", flexDirection: "column", gap: "30px", alignItems: "center"}}>
                <p class="home-call-to-action" style={{marginTop: "0px", cursor: "pointer", width: "auto"}}><b><u><NavLink to="/artigos" style={{color: "black", textDecoration: "none"}}>ACESSE TODOS OS ARTIGOS >></NavLink></u></b></p>
                { props.artigosMaisRecentes.slice(0,5).map((item) => (<ArtigoMedio artigo={item}/>))}
                    <p class="home-call-to-action" style={{marginTop: "0px", cursor: "pointer", width: "auto"}}><b><u><NavLink to="/artigos" style={{color: "black", textDecoration: "none"}}>ACESSE TODOS OS ARTIGOS >></NavLink></u></b></p>
            </div>
        </div>
    </div>
}

// import FacebookIcon from '@mui/icons-material/Facebook';
// import InstagramIcon from '@mui/icons-material/Instagram';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
// import EmailIcon from '@mui/icons-material/Email';
